<table *ngIf="extended" [ngClass]="tableClass">
  <thead>
    <tr>
      <th>{{ 'Start' | translate }}</th>
      <th>{{ 'End' | translate }}</th>
      <th>{{ 'Break' | translate }}</th>
      <th>{{ 'Worked' | translate }}</th>
      <th>{{ 'Payout' | translate }}</th>
      <th>{{ 'Plus minus' | translate }}</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let rateBlock of rateBlocks">
      <td>{{ rateBlock.starttime | time }}</td>
      <td>{{ rateBlock.endtime | time }}</td>
      <td>{{ rateBlock.break }}</td>
      <td>{{ rateBlock.duration | decimalToTime }}</td>
      <td>
        {{ rateBlock.duration + rateBlock.surcharge_pay | decimalToTime }} ({{
          rateBlock.paid_percentage | decimal: 2 : false : 'percent'
        }})
      </td>
      <td>
        {{ rateBlock.duration + rateBlock.surcharge_time | decimalToTime }} ({{
          rateBlock.time_percentage | decimal: 2 : false : 'percent'
        }})
      </td>
      <td *ngIf="rateBlock.is_overtime" class="text-right">
        <ng-template #overtimeTooltip>
          <div>
            <ng-container *ngIf="rateBlock.overtime">
              {{ 'Overtime' | translate }}: {{ rateBlock.overtime }}
            </ng-container>
          </div>
        </ng-template>
        <div class="btn button-icon bg-[#eaecf0]" [sbTooltip]="overtimeTooltip">
          <icon class="button-icon__icon" svgIcon="overtime"></icon>
        </div>
      </td>
      <td
        *ngIf="!rateBlock.is_overtime && (rateBlock.surcharge_pay !== 0 || rateBlock.surcharge_time !== 0)"
        class="text-right"
      >
        <ng-template #ratecardTooltip>
          <div>
            <ng-container
              *ngIf="!rateBlock.is_overtime && (rateBlock.surcharges_pay !== 0 || rateBlock.surcharges_time !== 0)"
            >
              {{ 'Rate card' | translate }}: {{ timesheet.RateCard?.name }}
            </ng-container>
          </div>
        </ng-template>
        <div class="btn button-icon bg-[#eaecf0]" [sbTooltip]="ratecardTooltip">
          <icon class="button-icon__icon" svgIcon="ratecard"></icon>
        </div>
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th></th>
      <th></th>
      <th></th>
      <th>{{ timesheet.Rates?.duration | decimalToTime }}</th>
      <th>{{ timesheet.Rates?.duration + timesheet.Rates?.surcharge_pay | decimalToTime }}</th>
      <th colspan="2">{{ timesheet.Rates?.duration + timesheet.Rates?.surcharge_time | decimalToTime }}</th>
    </tr>
  </tfoot>
</table>
<table *ngIf="!extended" [ngClass]="tableClass">
  <thead>
    <tr>
      <th translate>Time</th>
      <ng-container *subscriptionPlan="planType.BASIC; checkType: planCheckType.AT_LEAST">
        <th translate>Payout</th>
        <th translate>Plus minus</th>
      </ng-container>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let rateBlock of timesheet.Rates?.RateBlock">
      <td>{{ rateBlock.starttime | time }} - {{ rateBlock.endtime | time }} ({{ rateBlock.break }})</td>
      <ng-container *subscriptionPlan="planType.BASIC; checkType: planCheckType.AT_LEAST">
        <td>
          {{ rateBlock.duration + rateBlock.surcharge_pay | decimalToTime }} ({{
            rateBlock.paid_percentage | decimal: 2 : false : 'percent'
          }})
        </td>
        <td>
          {{ rateBlock.duration + rateBlock.surcharge_time | decimalToTime }} ({{
            rateBlock.time_percentage | decimal: 2 : false : 'percent'
          }})
        </td>
      </ng-container>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th>{{ timesheet.Rates?.duration | decimalToTime }}</th>
      <ng-container *subscriptionPlan="planType.BASIC; checkType: planCheckType.AT_LEAST">
        <th>{{ timesheet.Rates?.duration + timesheet.Rates?.surcharge_pay | decimalToTime }}</th>
        <th>{{ timesheet.Rates?.duration + timesheet.Rates?.surcharge_time | decimalToTime }}</th>
      </ng-container>
    </tr>
  </tfoot>
</table>
