import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { PlanType, SubscriptionCheckType } from '@app/+authenticated/+reports/shared/subscriptions/subscription.model';
import { IconComponent } from '@app/+authenticated/shared/icon.component';
import { DecimalToTimePipe } from '@app/pipes/decimal-to-time.pipe';
import { DecimalPipe } from '@app/pipes/decimal.pipe';
import { TimePipe } from '@app/pipes/time.pipe';
import { RateBlockModel, TimesheetModel } from '@app/reducers/orm/timesheet/timesheet.model';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipDirective } from '@sb/tooltip';

import { SubscriptionPlanDirective } from '../subscription-plan/subscription-plan.directive';

@Component({
  selector: 'rate-table',
  templateUrl: './rate-table.component.html',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    TimePipe,
    DecimalToTimePipe,
    DecimalPipe,
    TooltipDirective,
    IconComponent,
    SubscriptionPlanDirective,
  ],
})
export class RateTableComponent implements OnInit {
  @Input()
  public timesheet: TimesheetModel;

  @Input()
  public tableClass: string;
  @Input()
  public extended = false;

  public rateBlocks: Array<RateBlockModel & { overtime: string }> = [];
  public planType = PlanType;
  public planCheckType = SubscriptionCheckType;

  public ngOnInit() {
    for (const rateBlock of this.timesheet.Rates.RateBlock) {
      this.rateBlocks.push({
        ...rateBlock,
        starttime: rateBlock?.starttime,
        endtime: rateBlock?.endtime,
        surcharge_pay: parseFloat(rateBlock.surcharge_pay.toString()),
        surcharge_time: parseFloat(rateBlock.surcharge_time.toString()),
        overtime: `${Math.floor(parseFloat(rateBlock.duration.toString()))}h ${Math.round(
          (parseFloat(rateBlock.duration.toString()) % 1) * 60,
        )}m`,
      });
    }
  }
}
